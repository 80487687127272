<template>
    <div>
        <h3>{{ $t('filtres.critere_de_filtre') }}</h3>
        
        <!-- Filtre enregistrés sur cette config -->
        <ConfigFilter 
            v-for="(filter, index) in local_filters"
            :table_type="table_type"
            :key="'object_filters_'+index"
            :config_filter="filter" 
            :all_filters="all_filters" 
            :available_columns="columns"
            :type_search="type_search"
            :table_id="table_id"
            @addFilter="addFilter()"
            @rmFilter="rmFilter(index)"
        />
    </div>
</template>

<script type="text/javascript">
    import ConfigFilter from "GroomyRoot/components/Table/ConfigTable/ConfigFilter"
    import cloneDeep from 'lodash/cloneDeep.js'
    import arrayCompact from 'lodash/compact.js'

	export default {
        components: {
            ConfigFilter
        },
        props: {
            table_type: String, // La table_cle
            columns: Array,     // Les colonnes sur lesquelles on peut appliquer un critère de filtre
            filters: Array,      // Les filtres de la config en cours d'édition
            type_search: Object,
            table_id: Number
        },
        data() {
            return {
                all_filters: [],    // Tous les types de filtres existants
                local_filters: [],  // Une copie locale de filters
                tab_filters_qt_value: {}, // Objet avec en clé, le critère (l'action du critère), et en valeur, le nombre d'inputs
                lock_reload: false, // Vérrou de reload, pour éviter les boucles de watch (cf filters et validFilters)

                empty_filter: {
                    critere: '',
                    column: '',
                    value: [],
                }
            }
        },
        created() {
            this.init_component()
        },
        methods: {
            /* Initialisation des composants */
            init_component() {
                // On vide le tableau, si on vient de watch
                this.local_filters = []

                // Récupérer la liste des critères possibles
                this.all_filters = this.getConfig('action_filter')
                if(!this.all_filters) return

                this.all_filters.forEach((value, key) => {
                    this.tab_filters_qt_value[value.action] = value.value_qt
                })

                // Si on a déjà des filtres enregistrés et donnés en props
                if(this.filters && this.filters.length > 0) {
                    this.local_filters = this.prepareFilters()
                }
                // Sinon on initialise le tableau d'objets
                else {
                    this.addFilter()
                }
            },

            /* Ajout d'un filtre */
            addFilter() {
                this.local_filters.push(cloneDeep(this.empty_filter))
            },

            /* Suppression d'un filtre */
            rmFilter(index) {
                this.local_filters.splice(index, 1)
            },

            /* On formatte les données pour le formulaire. Principalement, repasser filter.value en tableau */
            prepareFilters() {
                const filters_formatted = cloneDeep(this.filters)

                filters_formatted.forEach((filter, index) => {
                    if(filter.column.endsWith('_date'))
                        filters_formatted[index].value = [new Date(filter.value[0])]
                    else if(this.type_search && filter.critere == 'isInList' && filter.value.includes(',') && Object.prototype.hasOwnProperty.call(this.type_search, filter.column))
                        filters_formatted[index].value = filter.value.split(',')
                    else
                        filters_formatted[index].value = filter.value.split('|')
                })

                return filters_formatted
            }
        },
        computed: {
            // Retourne les filtres entièrement configurés
            validFilters() {
                return this.local_filters.filter(filter => {
                    // On compact l'array, pour supprimer les valeurs insignifiantes (false, undefined, string vide, ...)
                    const clean_values = arrayCompact(filter.value)

                    // On regarde si des values sont nécessaires pour ce filtre
                    const qt_value = this.tab_filters_qt_value[filter.critere]

                    // Si on a une ou des values nécéssaires, on vérifie. Sinon, true
                    const cond_value = (clean_values.length == qt_value) // On a un array de values, dans filter.value
                    const value_condition = qt_value > 0 ? (cond_value) : true

                    return filter.critere && filter.column && value_condition
                })
            }
        },
        watch: {
            // Si on change de config sur le parent, les filtres sont à rafraichir
            'filters' () {
                if(!this.lock_reload) {
                    this.init_component()
                }
                this.lock_reload = false
            },
            // Dès qu'on a des filtres valides, on les emit au parent, pour que la config les récupére
            'validFilters' (val) {
                const good_format = cloneDeep(val)                

                good_format.forEach((filter, index) => {
                    // On récupère la quantité de values nécéssaire, pour supprimer le surplus
                    const qt_value = this.tab_filters_qt_value[filter.critere]
                    if(filter.value[0] && filter.value[0].code)
                        filter.value[0] = filter.value[0].code

                    if(filter.column.endsWith('_date') && filter.value[0])
                        good_format[index].value = [filter.value[0].toDateInputValue()]
                    else if(Array.isArray(filter.value[0]))
                        good_format[index].value = filter.value[0].map(val => val.code).join(',')
                    else
                        good_format[index].value = filter.value.join('|')
                })

                this.lock_reload = true
                this.$emit('update:filters', good_format)
            }
        }
	}
</script>
