import Accounting from "@/mixins/Accounting.js"
import Constants from '../../config.local.js'
import Countries from '@/assets/lang/countries/countries'
import Horse from '@/mixins/Horse.js'
import Lieux from '@/mixins/Lieux.js'
import Pension from '@/mixins/Pension.js'
import IntraLocation from "@/mixins/IntraLocation.js"
import Vue from 'vue'

export default {
	mixins: [Accounting, Horse, Lieux, Pension, IntraLocation],
	methods: {
		getAllQuotationStatus() {
			return [
				{code: "BRO", label: "Brouillon"},
				{code: "ENV", label: "Envoyé"},
				{code: "SIG", label: "Signé"},
				{code: "REF", label: "Refusé"},
				{code: "SUP", label: "Supprimé"},
				{code: "CON", label: "Converti"}
			]
		},
		async getAllAccountingPlan() {
			let accounting_plans = await this.loadAccountingFromLocal()

			let tab = []
			for (let index = 0; index < accounting_plans.length; index++) {
				const element = accounting_plans[index];
				tab.push({
					code: element.tiers.tiers_rs,
					label: element.tiers.tiers_rs,
				})
			}

			return tab
		},
		async getAllAccountingLabel() {
			let accounting_plans = await this.loadAccountingFromLocal()

			let tab = []
			for (let index = 0; index < accounting_plans.length; index++) {
				const element = accounting_plans[index];
				tab.push({
					code: element.accountingplan_label,
					label: element.accountingplan_label,
				})
			}

			return tab
		},
		async getUser() {
			let tab = []
			const url = this.constructRoute(Constants.USERS_LICENCE_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TableSearch::getUser", url)
			.catch(error => {
				console.error("TableSearch::getUser => ERROR", error)
				return tab
			})
			if(result) {
				for (let index = 0; index < result.retour.length; index++) {
					const element = result.retour[index];
					tab.push({
						code: element.firstname + ' ' + element.name,
						label: element.firstname + ' ' + element.name
					})
				}
			}
			
			return tab
		},
		async getAllPaymentMethod() {
			const payment_methods = this.getConfig('payment_method')
			let tab = []
			for (let index = 0; index < payment_methods.length; index++) {
				const element = payment_methods[index];
				tab.push({
					code: element.paymentmethod_label,
					label: element.paymentmethod_label,
				})
			}

			return tab
		},
		getAllColors() {
			return [
				{code: "#FF7C24",label: '<span style="background-color:#FF7C24"; class="tags"></span'},
				{code: "#D11543",label: '<span style="background-color:#D11543"; class="tags"></span'},
				{code: "#FFC031",label: '<span style="background-color:#FFC031"; class="tags"></span'},
				{code: "#54AFE2",label: '<span style="background-color:#54AFE2"; class="tags"></span'},
				{code: "#EB3C96",label: '<span style="background-color:#EB3C96"; class="tags"></span'},
				{code: "#197D8D",label: '<span style="background-color:#197D8D"; class="tags"></span'},
				{code: "#532B87",label: '<span style="background-color:#532B87"; class="tags"></span'},
				{code: "#C279D6",label: '<span style="background-color:#C279D6"; class="tags"></span'},
				{code: "#3A5827",label: '<span style="background-color:#3A5827"; class="tags"></span'},
				{code: "#158D4D",label: '<span style="background-color:#158D4D"; class="tags"></span'},
				{code: "#14529B",label: '<span style="background-color:#14529B"; class="tags"></span'}
			]
		},
		getAllCountries() {
			const countries = Countries[Vue.i18n.locale()].label
			const countries_values = Object.values(countries)

			let temp = []
			for (let i = 0; i < countries_values.length; i++) {
				temp.push({code: countries_values[i], label: countries_values[i]})
			}

			return temp
		},
		async getAllRaces() {
			const races = await this.getRaces()

			let temp = []
			races.forEach(race => {
				temp.push({code: race.race_label, label: race.race_label})
			})

			return temp
		},
		async getAllCodesRaces() {
			const races = await this.getRaces()

			let temp = []
			races.forEach(race => {
				temp.push({code: race.race_code, label: race.race_code})
			})

			return temp
		},
		getAllTypesContract() {
			return [
				{code: 'etalonnier',label: this.getTrad('monte.etalonnier')},
				{code: 'sur_pool',label: this.getTrad('monte.sur_pool')},
				{code: 'sur_parts',label: this.getTrad('monte.sur_parts')}
			]
		},
		async getAllRobes() {
			const robes = await this.getRobes()

			let temp = []
			robes.forEach(robe => {
				temp.push({code: robe.robe_label, label: robe.robe_label})
			})

			return temp
		},
		async getAllSexes() {
			const sexes = await this.getSexes()

			let temp = []
			sexes.forEach(sex => {
				temp.push({code: sex.sexe_code, label: sex.sexe_label})
			})

			return temp
		},
		async getAllCategories() {
			const categories = await this.getAllCategorie()

			let temp = []
			categories.forEach(categorie => {
				temp.push({code: categorie.categorie_libelle, label: categorie.categorie_libelle})
			})

			return temp
		},
		async getAllResidences() {
			const all_lieux = await this.getLieux(false)
			const residences = all_lieux.filter(lieu => lieu.lieu_type == 1 && lieu.lieu_archive == 0)

			let temp = []
			residences.forEach(residence => {
				temp.push({code: residence.lieu_label, label: residence.lieu_label})
			})

			return temp
		},
		async getAllIntraLocations() {
			const intra_locs = await this.getIntraLocations(false)

			let temp = []
			intra_locs.forEach(loc => {
				temp.push({code: loc.intralocation_label, label: loc.intralocation_label})
			})

			return temp
		},
		async getAllPensions() {
			const pensions = await this.getPensions()

			let temp = []
			pensions.forEach(pension => {
				temp.push({code: pension.pension_label, label: pension.pension_label})
			})

			return temp
		},
		getStockFluxType() {
			return [
				{code: "out",label: '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="long-arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="typeColIndentifer red svg-inline--fa fa-long-arrow-right fa-w-14"><path fill="currentColor" d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z" class=""></path></svg>'},
				{code: "in",label: '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="long-arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="typeColIndentifer green svg-inline--fa fa-long-arrow-left fa-w-14"><path fill="currentColor" d="M136.97 380.485l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113l83.928-83.444c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0l-116.485 116c-4.686 4.686-4.686 12.284 0 16.971l116.485 116c4.686 4.686 12.284 4.686 16.97-.001z" class=""></path></svg>'},
				{code: "used",label: '<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="syringe" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="typeColIndentifer svg-inline--fa fa-syringe fa-w-16"><path fill="currentColor" d="M509.7 81.5L430.5 2.3c-3.1-3.1-8.2-3.1-11.3 0l-11.3 11.3c-3.1 3.1-3.1 8.2 0 11.3l28.3 28.3-45.3 45.3-56.6-56.6-17-17c-3.1-3.1-8.2-3.1-11.3 0l-11.3 11.3c-3.1 3.1-3.1 8.2 0 11.3l17 17L84.5 291.7c-17.3 17.3-25.6 41.1-23 65.4l7.1 63.6L2.3 487c-3.1 3.1-3.1 8.2 0 11.3l11.3 11.3c3.1 3.1 8.2 3.1 11.3 0l66.3-66.3 63.6 7.1c23.9 2.6 47.9-5.4 65.4-23l227.2-227.2 17 17c3.1 3.1 8.2 3.1 11.3 0L487 206c3.1-3.1 3.1-8.2 0-11.3l-73.5-73.5 45.3-45.3 28.3 28.3c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.2 3.1-8.2 0-11.4zm-84.9 96.2L197.7 404.9c-10.4 10.4-24.6 15.4-39.2 13.8l-58.5-6.5-6.5-58.5c-1.6-14.6 3.4-28.9 13.8-39.2l23.5-23.5 39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6 45.3-45.3 39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6 45.3-45.3 39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l11.3-11.3c3.1-3.1 3.1-8.2 0-11.3L289 132.4l45.3-45.3 90.5 90.6z" class=""></path></svg>'},
				{code: "destroy",label: '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="typeColIndentifer svg-inline--fa fa-trash-alt fa-w-14"><path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z" class=""></path></svg>'}
			]
		},
		getAllInvoiceSupplierStatus() {
			return [
				{code: 'partially_paid',label: this.getTrad('invoice.supplier_status.partially_paid')},
				{code: 'paid',label: this.getTrad('invoice.supplier_status.paid')},
				{code: 'to_pay',label: this.getTrad('invoice.supplier_status.to_pay')}
			]
		}
	}
}
