<template>
    <div class="row critere align-items-end">
        <!-- Le choix de la colonne -->
        <div class="col-12 col-md">
            <div class="form-group">
                <label>{{ $t('filtres.colonne') | capitalize }}</label>
                <e-select
                    :options="available_columns"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="true"
                    :search="true"
                    :placeholder="$t('filtres.selectionnez_colonne')"
                    v-model="config_filter.column"
                >
                    <template slot="singleLabel" slot-scope="props">
                        {{ getColumnTrad(table_type, props.option) }}
                    </template>
                    <template slot="option" slot-scope="props">
                        {{ getColumnTrad(table_type, props.option) }}
                    </template>
                </e-select>

            </div>
        </div>

        <!-- Le choix du critère -->
        <div class="col-12 col-md">
            <div class="form-group">
                <label>{{ $t('filtres.critere') | capitalize }}</label>
                <e-select
                    :options="all_filters"
                    :searchable="true"
                    :allow-empty="false"
                    :show-labels="true"
                    :search="true"
                    :placeholder="$t('filtres.selectionnez_critere')"
                    :value="local_critere"
                    @select="emitNewCritere"
                >
                    <template slot="singleLabel" slot-scope="props">
                        {{ $t('filtres.'+props.option.label) }}
                    </template>

                    <template slot="option" slot-scope="props">
                        {{ $t('filtres.'+props.option.label) }}
                    </template>
                </e-select>
            </div>
        </div>

        <!-- La valeur sur du filtre -->
        <div class="col">
            <div class="form-group" >
                <label>{{ $t('global.valeur') | capitalize }}</label>
                <input
                    v-if="!config_filter.critere || tab_filters_qt_value[config_filter.critere] == 0"
                    class="form-control"
                    value="NC"
                    readonly
                >
                <template v-else v-for="(val, index) in tab_filters_qt_value[config_filter.critere]">
                     <e-select
                        v-if="hasSearchValues"
                        :key="'tab_filters_qt_value_'+index"
                        track-by="code"
                        label="label"
                        :options="searchValues"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="true"
                        :search="true"
                        v-model="config_filter.value[index]"
                        :placeholder="$t('global.rechercher')"
                        :multiple="local_critere.action == 'isInList'"
                    >
                        <template slot="option" slot-scope="{ option }"><span v-html="cleanHTML(option.label)"></span></template>
                        <template slot="singleLabel" slot-scope="{ option }"><span v-html="cleanHTML(option.label)"></span></template>
                        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                    </e-select>
                    <e-datepicker 
                        v-else-if="config_filter.column.endsWith('_date')"
                        :key="'tab_filters_qt_value_'+index"
                        v-model="config_filter.value[index]"
                    />
                    <input
                        v-else
                        :key="'tab_filters_qt_value_'+index"
                        type="text"
                        class="form-control"
                        v-model="config_filter.value[index]"
                    >
                </template>
            </div>
        </div>
        <div class="col-auto">
            <button type="button" @click="rmFilter()" class="btn btn-secondary mb-3"><font-awesome-icon :icon="['fal', 'trash-alt']" /></button>
        </div>
        <div class="col-auto">
            <button type="button" @click="addFilter()" class="btn btn-secondary mb-3"><font-awesome-icon :icon="['fal', 'plus-circle']" /></button>
        </div>
    </div>
</template>

<script>
import TableMixin from '@/mixins/Table.js'
import TableSearchMixin from '@/mixins/TableSearch.js'
    import xss from 'xss'

export default {
    mixins: [TableMixin, TableSearchMixin],
    props: {
        table_type: String,       // La table_cle
        config_filter: Object,    // Les filtres de la config en cours
        all_filters: Array,       // Tous les filtres existants
        available_columns: Array, // Les colonnes sur lesquelles on peut appliquer un critère de filtre
        type_search: Object,
        table_id: Number
    },
    data() {
        return {
            local_critere: '', // On garde une version locale, car pas le même format entre l'objet final et ce que demande le e-select,
            searchValues: [],
            oldColumn: null
        }
    },
    mounted() {
        this.init_component()
    },
    methods: {
        async init_component() {
            if(!this.config_filter || !this.config_filter.critere) return

            await this.displayValues()
            const actual_critere = this.config_filter.critere
            this.local_critere = this.all_filters.find(filter => filter.action == actual_critere)

        },
        async displayValues() {
            if(this.hasSearchValues) {
                this.searchValues = await this[this.type_search[this.config_filter.column]]()
                const value = this.config_filter.value

                if(Array.isArray(value)) 
                    this.config_filter.value = [value.map(val => this.searchValues.find(v => v.code == val))]
                else
                    this.config_filter.value = [this.searchValues.find(val => val.code == value[0])]
            }
        },
        addFilter() {
            this.$emit('addFilter')
        },
        rmFilter() {
            this.$emit('rmFilter')
        },
        /* On envoie un emit. Etant donné que tout l'objet config_filter est sync, on en fait une copie pour éditer juste le critère, puis on l'envoie
          ( On ne peut modifier à la vollée un props, même sync )
        */
        emitNewCritere(critere) {
            const config_copy = this.config_filter
            config_copy.critere = critere.action
            this.$emit('update:config_filter', config_copy)
        },

        cleanHTML(input) {
            return xss(input)
        }
    },
    computed: {
        // Objet avec en clé, le critère (l'action du critère), et en valeur, le nombre d'inputs
        tab_filters_qt_value() {
            let temp = {}

            this.all_filters.forEach((value, key) => {
                temp[value.action] = value.value_qt
            })

            return temp
        },

        hasSearchValues() {
            if(!this.type_search) return false
            return Object.prototype.hasOwnProperty.call(this.type_search, this.config_filter.column)
        },

        // searchValues() {
            
        // }
    },
    watch: {
        table_id() {
            this.oldColumn = null
        },
        'config_filter.critere' (val) {
            // Suite à l'emit de emitNewCritere, on update notre criter local, pour l'affichage
            this.local_critere = this.all_filters.find(filter => filter.action == val)
        },
        'config_filter.column' (val) {
            if(this.oldColumn && this.oldColumn !== val) {
                this.config_filter.value = []
            }
            this.oldColumn = val
            this.displayValues()
        }
    }
}
</script>